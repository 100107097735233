import { FC } from "react";
import { Routes, Route } from "react-router-dom";
import loadable from "@loadable/component";
import RouteHome from "src/routes/home";
import RouteAbout from "src/routes/about";
import RouteError from "src/routes/error";

const RouteSvg = loadable(() => import("src/routes/svg"));
const RouteSvgGrid = loadable(() => import("src/routes/svg-grid"));
const RouteSvgHexagonMath = loadable(() => import("src/routes/svg-hexagon-math"));
const RouteMathSvgGrid = loadable(() => import("src/routes/math-svg-grid"));

export const LayoutRoutes: FC = () => {
  return (
    <>
      <Routes>
        <Route path="*" element={<RouteError />} />
        <Route path="/" element={<RouteHome />} />
        <Route path="/about" element={<RouteAbout />} />
        <Route path="/svg" element={<RouteSvg />} />
        <Route path="/svg-hexagon-math" element={<RouteSvgHexagonMath />} />
        <Route path="/svg-grid" element={<RouteSvgGrid />} />
        <Route path="/math-svg-grid" element={<RouteMathSvgGrid />} />
      </Routes>
    </>
  );
};
